body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

.page {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);

  box-sizing: border-box;
  padding-top: 80px;

  display: flex;
  flex-direction: column;
}

/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.flex-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.react-html5-camera-photo {
  position: relative;
  /* position: absolute; */
  /* flex: 1; */
  /* display: flex; */
  height: 100%;
  width: 100%;
  /* top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  background-color: blue;
}

.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
  overflow: hidden;
  position: absolute;
  min-width: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background-size: cover;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: auto;
}

.background {
  /* position: absolute; */
  flex: 1;
  display: "flex";
  width: 100%;
  height: 100%;
  /* top: 0; */
  /* left: 0; */
  background: "red";
  /* background: url(background-image.jpg) center; */
  /* background-size: cover; */
}

.gt-title {
  color: var(--unnamed-color-0bbbef);
  text-align: left;
  text-transform: uppercase;
  font: normal normal bold 12px;
  letter-spacing: 0px;
  color: #0bbbef;
  opacity: 1;
  padding-bottom: 5px;
}
.gt-title span {
  opacity: 0.6;
}

.gt-text {
  top: 159px;
  left: 68px;
  /* width: 143px; */
  /* height: 23px; */
  text-align: left;
  font: normal normal 300 17px;
  letter-spacing: 0px;
  color: #1d1d1b;
  opacity: 1;
  padding-bottom: 10px;
}

.welcome {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.welcome-body {
  display: flex;
  flex-direction: column;
}

.welcome-image {
  padding-bottom: 40px;
}

.welcome-title {
  top: 545px;
  left: 48px;
  width: 243px;
  height: 24px;
  text-align: left;
  font: normal normal bold 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #1d1d1b;
  opacity: 1;
  padding-bottom: 20px;
}

.welcome-perex {
  top: 593px;
  left: 48px;
  width: 288px;
  height: 76px;
  text-align: left;
  font: normal normal 300 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #1d1d1b;
  opacity: 1;
  padding-bottom: 20px;
}

.button-camera {
  margin: 20px auto;

  cursor: pointer;
  /* top: 724px; */
  /* left: 112px; */
  position: relative;

  width: 152px;
  height: 56px;
  background: var(--unnamed-color-0bbbef) 0% 0% no-repeat padding-box;
  background: #0bbbef 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 24px #00000029;
  border-radius: 28px;
  opacity: 1;
  text-align: center;

  /* position: fixed; */
  /* bottom: 0; */
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 850px) {
  .button-camera {
    position: relative;
  }

  .welcome {
    justify-content: unset;
  }
}

.result {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 100;
}

.result-content {
  width: 320px;
}
